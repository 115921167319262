import React, { useState } from 'react'

import Skydinelogo from '../assets/skydinelogo.png'
import BurgerMenu from '../assets/burgerwhite.svg'
import Photo1 from '../assets/ambience1.JPG'
import { Link } from 'react-router-dom'

const Navbar = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    
    <header className="header">
    <nav>
      <div className="nav__header">
        <div className="nav__logo">
          <a href="#">
            <img src={Skydinelogo} alt="logo" className="nav__logo-dark" />
            <img src={Skydinelogo} alt="logo" className="nav__logo-white mobilelogo" />
          </a>
        </div>

        <div className="nav__menu__btn" id="menu-btn" onClick={toggleMenu}>
      <img src={BurgerMenu} alt="" />
    </div>
      </div>
      
      <ul className={`nav__links ${isMenuOpen ? 'nav__links--open' : ''}`} id="nav-links">
        {/* <li><a href="#home" onClick={toggleMenu}>HOME</a></li> */}
        <li><a href="#catering" onClick={toggleMenu}>CATERING</a></li>
        <li><a href="#menu" onClick={toggleMenu}>MENU</a></li>
        {/* <li><a href="#event" onClick={toggleMenu}>EVENTS</a></li> */}
      
        {/* <li><a href="#contact" onClick={toggleMenu}>CONTACT US</a></li> */}
      </ul>
    </nav>
    <div className="section__container header__container" id="home">
      <div className="header__image">
        <img className='border50' src={Photo1} alt="header" />
      </div>
      <div className="header__content">
        
        <h1>Best in Taste</h1>
        <h3 className='colorwhite'>Elevate Your Tastebuds with Exquisite Rooftop Ambience and Diverse Culinary Delights</h3>
      </div>
    </div>
  </header>

  )
}

export default Navbar