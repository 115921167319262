import './App.css';


import React, { useState, useRef, useEffect } from 'react';
import Skydine from './assets/skydinelogo.png'
import Photo1 from './assets/photo1.jpeg'
import Manchurian from './assets/manchurian.jpeg'
import Mojitos from './assets/mojitos.jpeg'
import Shahipaneer from './assets/shahipaneer.jpeg'
import Event from './assets/event1.jpg'
import Skydinelogo from './assets/skydinelogo.png'
import BurgerMenu from './assets/burgerwhite.svg'
import Navbar from './components/navbar';

const App = () => {

  const [formData, setFormData] = useState({
    numberOfPeople: '',
    eventType: '',
    name: '',
    email: '',
    contact: '',
    date: ''

  });

  const [isFocused, setIsFocused] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const cateringRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains "/catering" and scroll to the section
    if (window.location.hash === '#/catering') {
      cateringRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);



  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { numberOfPeople, name, email, contact, date, eventType } = formData;
    const phone = '9511719884';
    const message = `
    Hello, my name is ${name}. I can be reached at ${contact} via email at ${email}. I am looking to arrange catering for an ${eventType} event for ${numberOfPeople} people on ${date}.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };


  const handleSubmit1 = (e) => {
    e.preventDefault();

    // Get form data
    const name = document.getElementById('name').value;
    const date = document.getElementById('date').value;
    const time = document.getElementById('time').value;
    const people = document.getElementById('people').value;
    const phoneNumber = '9769622215';

    // Construct WhatsApp message
    const message = `Hi my name is ${name}, I want to book a table for ${people} people on ${date} at ${time}`;

    // Encode message for URL
    const encodedMessage = encodeURIComponent(message);

    // Construct WhatsApp link with the encoded message
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

    // Open WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };



  return (
    <>
      <Navbar />

      {/* <section className="section__container banner__container" id="special">
        <div className="banner__card">
          <p>TRY IT OUT TODAY</p>
          <h4>MOST POPULAR BURGER</h4>
        </div>
        <div className="banner__card">
          <p>TRY IT OUT TODAY</p>
          <h4>MORE FUN<br />MORE TASTE</h4>
        </div>
        <div className="banner__card">
          <p>TRY IT OUT TODAY</p>
          <h4>FRESH & CHILI</h4>
        </div>
      </section> */}

      <div className="catering" id="catering">
        <div className=" reservation__container">
          <h1>CATERING</h1>
          <p className='cateringcontent'>

            SkyDine Catering is your one-stop solution for all catering needs, providing exceptional service for a variety of events. Whether it's a corporate delivery, a family gathering, a casual luncheon, an elegant wedding, or a festive celebration, we’ve got you covered. Our experienced and dedicated catering team is fully equipped to manage events of any scale—from intimate gatherings of 50 people to grand occasions with up to 3,000 guests. We take pride in delivering a seamless and memorable dining experience, tailored to meet the unique demands of each event, ensuring every detail is expertly handled.
          </p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className='inputClass'
              placeholder='Name'
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="number"
              className='inputClass'
              placeholder='Contact'
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              className='inputClass'
              placeholder='Email'
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <select
              id="eventType"
              name="eventType"
              value={formData.eventType}
              onChange={handleChange}
              required
            >
              <option value="" selected>Event Type:</option>
              <option value="indoor">Indoor</option>
              <option value="outdoor">Outdoor</option>
            </select>

            <div>
            {!isFocused && <span className="absdate">Select Date</span>}
              <input type="date"
                value={formData.date}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                id="date"
                name="date"
                className='datePicker'
                placeholder="Select a date"
                required />
            </div>
            <input
              type="number"
              className='inputClass'
              placeholder='Number of people'
              id="numberOfPeople"
              name="numberOfPeople"
              value={formData.numberOfPeople}
              onChange={handleChange}
              required
            />

            <button className="btn" type="submit">Submit</button>
          </form>
        </div>
      </div>

      <section className="section__container order__container" id="menu">
        {/* <h3>ALWAYS TASTEY BURGER</h3> */}
        <h2 className="section__header">CHOOSE & ENJOY</h2>
        <p className="section__description">
          Whether you seek classic delights or adventurous flavors, your culinary journey begins at The Skydine. Indulge in starters like paneer soya chaap manchurian, main courses like paneer butter masala, and refreshing mojitos. Welcome to the ultimate rooftop dining experience.
        </p>
        <div className="order__grid">
          <div className="order__card">
            <img src={Manchurian} alt="order" />
            <h4>Manchurian</h4>
            <p>
              At Skydine Restaurant, start your meal with our delectable Manchurian. Enjoy crispy vegetable balls tossed in a savory, spicy sauce, perfect for a flavorful beginning.
            </p>
            <a href="https://www.zomato.com/pune/the-sky-dine-viman-nagar" target="_blank">
              <button className="btn">ORDER NOW</button>
            </a>

          </div>
          <div className="order__card">
            <img src={Shahipaneer} alt="order" />
            <h4>Paneer Butter Masala</h4>
            <p>
              Savor the rich and creamy Paneer Butter Masala at Skydine. Tender paneer cubes in a luxurious tomato gravy blend traditional spices with an irresistible taste.
            </p>
            <a href="https://www.zomato.com/pune/the-sky-dine-viman-nagar" target="_blank">
              <button className="btn">ORDER NOW</button>
            </a>
          </div>
          <div className="order__card">
            <img src={Mojitos} alt="order" />
            <h4>Mojitos</h4>
            <p>
              Refresh yourself with our signature Mojitos at Skydine. Crafted with fresh mint, zesty lime, and a touch of sweetness, they are the perfect for your rooftop dining experience.
            </p>
            <a href="https://www.zomato.com/pune/the-sky-dine-viman-nagar" target="_blank">
              <button className="btn">ORDER NOW</button>
            </a>
          </div>
        </div>
      </section>

      {/* <section className="section__container event__container" id="event">
        <div className="event__content">
          <div className="event__image">
            <img src={Event} alt="event" />
          </div>
          <div className="event__details">
            <h3>Discover</h3>
            <h2 className="section__header">UPCOMING EVENTS</h2>
            <p className="section__description">
              From exclusive burger tastings and chef collaborations to community
              outreach initiatives and seasonal celebrations, there's always
              something special on the horizon at Burger Company. Be the first to
              know about our upcoming events, promotions, and gatherings as we
              continue to bring joy and flavor to our cherished customers. Join us
              in creating memorable moments and delicious memories together!
            </p>
          </div>
        </div>
      </section> */}



      <footer className="footer">
        <div className=" footer__container">
          <div className="footer__logo">
            <img src={Skydine} alt="logo" />
          </div>
          <div className="footer__content">
            <p>
              Welcome to The Skydine, where passion for exceptional food and
              genuine hospitality come together. Our story is one of dedication to
              crafting the perfect experience of taste and ambience, from sourcing the finest
              ingredients to delivering unparalleled taste in every bite.
            </p>
            <div>
              <ul className="footer__links">
                <li>
                  <span><i className="ri-map-pin-2-fill"></i></span>
                  Viman Nagar, Pune, 411014
                </li>
                <li>
                  <span><i className="ri-mail-fill"></i></span>
                  theskydine24@gmail.com
                </li>
              </ul>
              <div className="footer__socials">
                <a href="#"><i className="ri-facebook-circle-fill"></i></a>
                <a href="#"><i className="ri-instagram-fill"></i></a>
                <a href="#"><i className="ri-twitter-fill"></i></a>
                <a href="#"><i className="ri-whatsapp-fill"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bar">

        </div>
      </footer>

      <div className='stickybtn'>
         <a  href="tel:+919511719884">
         <i class="fa-solid fa-phone"></i>
          Call Us</a>
      </div>

      <script src="https://unpkg.com/scrollreveal"></script>
      <script src="main.js"></script>
    </>
  );
};

export default App;